/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FaceIcon from "@material-ui/icons/Face";
import AccessTimeSharpIcon from '@material-ui/icons/AccessTimeSharp';
import BuildIcon from "@material-ui/icons/Build";
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";

// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle, cardLink, cardSubtitle } from "assets/jss/material-kit-react.js";

import Button from "components/CustomButtons/Button.js";
import { InputAdornment } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput";
import { Message, People, Email } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function SectionHome() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
      <h2 align="center">How to windsport.io</h2>
      <h4 align="center">Discover and create courses near you</h4>

        <GridContainer container direction="row" justify="center" alignItems="stretch">
          <GridItem xs={12} sm={6} md={4}>
          <Card style={{width: "18rem"}}>
            <CardBody>
            <h4 className={classes.cardTitle}>1. Pick a course</h4>
              <h6 className={classes.cardSubtitle}>Start right now</h6>
              <p>
                The easiest way to start is just to do your windsport. Decide your location, select a course and include it into your day.
              </p>
              <a
                href="#wio"
                className={classes.cardLink}
                onClick={(e) => e.preventDefault()}>
                Sign in
              </a>
            </CardBody>
          </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
          <Card style={{width: "18rem"}}>
            <CardBody>
              <h4 className={classes.cardTitle}>2. Record your run</h4>
              <h6 className={classes.cardSubtitle}>Easy doing and enjoy your trip</h6>
              <p>
                Just start a track or tracing on your already existing equipment or personal devices. No new hardware needed. 
              </p>
              <a
                href="#wio"
                className={classes.cardLink}
                onClick={(e) => e.preventDefault()}>
                Sign in
              </a>
            </CardBody>
          </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
          <Card style={{width: "18rem"}}>
            <CardBody>
              <h4 className={classes.cardTitle}>3. Upload and compare</h4>
              <h6 className={classes.cardSubtitle}>Show your achievements</h6>
              <p>
                Back on land, just save your trace data. Upload the file, compare and show pride to club members or competitors.
              </p>
              <a
                href="#wio"
                className={classes.cardLink}
                onClick={(e) => e.preventDefault()}>
                Sign in
              </a>
            </CardBody>
          </Card>
          </GridItem>
        </GridContainer>
        <br />
      </div>

      <div className={classes.container}>
      <h2 align="center">Why join windsport.io</h2>
      <h4 align="center">You'll get, what you really need - freedom - tell us, what you are missing</h4>
      <br/>
      </div>
      <div className={classes.container}>
      <GridContainer container direction="row" justify="center" alignItems="stretch">
          <GridItem xs={12} sm={6} md={3} className={classes.textLeft}>
          <Card style={{width: "16rem"}}>
            <CardHeader color="primary"><b>Personal fun and passion</b></CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Choose the right time</h4>
              <p>
              You decide your start time. Results are collected on the side. 
              You can share your time with family and friends while following your passion.
              </p>
              <Button color="primary">Sign in</Button>
            </CardBody>
          </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} className={classes.textLeft}>
          <Card style={{width: "16rem"}}>
            <CardHeader color="primary"><b>Training and competition</b></CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Aim your objectives</h4>
              <p>
              Objectives are achieved by delivering key results. Follow any developments 
              and analyze details. This is what we will support you in.
              </p>
              <Button color="primary">Sign in</Button>
            </CardBody>
          </Card>
          </GridItem>
        </GridContainer>

      </div>

      <div className={classes.container}>
      <br></br>
        <h2 align="center">Just leave your message here</h2>
        <br />
              <form>
                <CustomInput
                  labelText="name"
                  id="name"
                  formControlProps={{
                      fullWidth: true
                  }}
                  inputProps={{
                      endAdornment: (<InputAdornment position="end"><People/></InputAdornment>)
                  }}
                />
                <CustomInput
                  labelText="email"
                  id="email"
                  formControlProps={{
                      fullWidth: true
                  }}
                  inputProps={{
                      endAdornment: (<InputAdornment position="end"><Email/></InputAdornment>)
                  }}
                />
                <CustomInput
                  labelText="your message"
                  id="msg"
                  multiline={true}
                  rowsmax={4}
                  formControlProps={{
                      fullWidth: true
                  }}
                  inputProps={{
                      endAdornment: (<InputAdornment position="end"><Message/></InputAdornment>)
                  }}
                />
                <Button color="primary">Send</Button>
              </form>
        <br />
      </div>

      <div className={classes.container}>
      <br></br>
        <h4 align="center">
              <b>Most important: FAIR WINDS and STAY SAFE</b><br />
              Do the course as you can effort it - Create new courses in your sailing area<br />
              Have fun and enjoy your wind sport - Share your adventures with all of us
            </h4>
        <br />
      </div>

      </div>
    );
  }

  /* 
          <GridContainer className={classes.textLeft} justify="center">
          <GridItem xs={12} sm={12} md={3}>
            <Card style={{width: "16rem"}}>
              <CardHeader color="primary"><b>Classes and championships</b></CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>Change the game</h4>
                <p>
                  Manage boats and results precisely. Replay to give feedback after a race.
                  Publish without hassles to enjoy the special community of classes.
                </p>
                <Button color="primary">Read more</Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
          <Card style={{width: "16rem"}}>
            <CardHeader color="primary"><b>Events and event support</b></CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Provide great experience</h4>
              <p>
                Focus your core processes and provide quality experiences to attendees.
                Use support which gives you and your guests access to what matters.
              </p>
              <Button color="primary">Read more</Button>
            </CardBody>
          </Card>
          </GridItem>
        </GridContainer>
  */
